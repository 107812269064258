// import Cryptr from "cryptr";
import clsx from "clsx";
import { join } from "lodash";
import { twMerge } from "tailwind-merge";
import ArabicFlag from "~/svgs/ArabicFlag";
import EnglishFlag from "~/svgs/EnglishFlag";

interface Languages {
  [key: string]: {
    name: string;
    icon: JSX.Element;
    dir: string;
  };
}

export function cn(...inputs: any[]) {
  return twMerge(clsx(inputs));
}

export type ExcludeResponse<T> =
  T extends Promise<infer U>
    ? U extends Response
      ? never // Exclude Response
      : U // Extract the other type
    : never;

export enum OS {
  Android = "Android",
  iOS = "iOS",
}

export const languages: Languages = {
  ar: {
    name: "عربي",
    icon: <ArabicFlag />,
    dir: "rtl",
  },
  en: {
    name: "English",
    icon: <EnglishFlag />,
    dir: "ltr",
  },
};

export function getMobileOperatingSystem() {
  if (navigator) {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return OS.Android;
    }

    if (/android/i.test(userAgent)) {
      return OS.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return OS.iOS;
    }
  }

  return null;
}

export function appLink() {
  switch (getMobileOperatingSystem()) {
    case OS.Android:
      return "https://apps.apple.com/eg/app/wearby/id6502705738";
      return "https://play.google.com/store/apps/details?id=com.wearby";
    case OS.iOS:
      return "https://apps.apple.com/eg/app/wearby/id6502705738";
    default:
      return "https://apps.apple.com/eg/app/wearby/id6502705738";
      return "https://play.google.com/store/apps/details?id=com.wearby";
  }
}

export function localeAttrName(attr: string, locale: string): string {
  return `${attr}${locale === "ar" ? "_ar" : ""}`;
}

export function localeAttr<T, K extends keyof T>(
  obj: T,
  attr: K,
  locale: string,
): T[K] {
  return obj[localeAttrName(attr as string, locale) as K];
}

export function formatDate(dateStr: string) {
  return new Date(dateStr).toLocaleDateString("en-GB");
}

export function cutTextInMiddle(text: string) {
  const halfIndex = text.length / 2;
  let firstHalf = text.substring(0, halfIndex);
  let secondHalf = text.substring(halfIndex);

  if (!firstHalf.endsWith(" ") && !secondHalf.startsWith(" ")) {
    let nearestSpaceIndex = secondHalf.indexOf(". ");
    if (nearestSpaceIndex === -1)
      nearestSpaceIndex = firstHalf.lastIndexOf(". ");
    else nearestSpaceIndex += halfIndex;
    nearestSpaceIndex += 1; // To keep the dot in the first half

    firstHalf = text.substring(0, nearestSpaceIndex);
    secondHalf = text.substring(nearestSpaceIndex);
  }

  return [firstHalf, secondHalf];
}

export function cutNSentences(text: string, n: number) {
  const separatedStr = text.split(". ");
  if (n >= separatedStr.length) return [text, ""];
  let firstStr = separatedStr.slice(0, n).join(". ");
  let secondStr = separatedStr.slice(n).join(". ");
  return [firstStr, secondStr];
}

export function cutNWords(
  text: string,
  n: number,
  fromLast: boolean = false,
  separator: string = " ",
) {
  const separatedStr = text.split(" ");
  if (n >= separatedStr.length) return [text, ""];

  let firstStr = fromLast
    ? separatedStr.slice(0, separatedStr.length - n).join(separator)
    : separatedStr.slice(0, n).join(separator);
  let secondStr = fromLast
    ? separatedStr.slice(-n).join(separator)
    : separatedStr.slice(n).join(separator);

  return [firstStr, secondStr];
}

export const decryptValue = (value: string) => {
  return JSON.parse(atob(value));
};

export function localeImg(imagePath: string, locale: string): string {
  if (locale === "ar") {
    const dotIndex = imagePath.lastIndexOf(".");
    if (dotIndex === -1) return imagePath;

    const arabicPath =
      imagePath.slice(0, dotIndex) + "-ar" + imagePath.slice(dotIndex);

    return arabicPath;
  } else return imagePath;
}
